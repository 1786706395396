import React, { useEffect, useState } from 'react';
import { Box, Button } from '@heycater/design-system';
import { ButtonBase, Hidden } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { cssVars } from 'lib/cssVariables';
import { useBreakpointDown } from 'qualification/hooks/useBreakpoints';
import useCurrentUser from 'qualification/hooks/useCurrentUser';
import { CONTACT_PHONE } from 'shared/constants';
import { CUSTOMER_APP_HOST, marketplaceURL } from 'shared/helpers/urls';
import useAuthentication from 'shared/hooks/useAuthentication';
import { useElementSize } from 'shared/hooks/useElementSize';
import NewPill from 'shared/layouts/NewPill';
import {
  formatRating,
  formatRatingCount,
  getRating,
  getRatingCount,
} from 'shared/services/trustedShops/handleRatings';
import { Ratings } from 'shared/services/trustedShops/model';
import Logo from 'shared/svg/Logo';
import MenuIcon from 'shared/svg/MenuIcon';
import TrustedShopsLogo from 'shared/svg/TrustedShopsLogo';
import TrustedShopsStars from 'shared/svg/TrustedShopsStars';
import { buildFunnelUrl } from 'static_pages/marketing_pages/helpers';
import Container from 'system/components/Container';

type LinkData = {
  href: string;
  outsideLink?: boolean;
  label: string;
  new?: boolean;
  oustideLink?: boolean;
  expandable?: boolean;
};

type MainLink = LinkData & {
  children?: LinkData[];
};
interface Props {
  hideNavigationLinks?: boolean;
  renderCTA?: React.ReactNode;
  ratings: Ratings;
  mainLinks?: MainLink[];
}

type VisibleDropdown = 'account' | 'more';

function Navigation({
  ratings,
  hideNavigationLinks,
  renderCTA,
  mainLinks,
}: Props) {
  const { t } = useTranslation('static_pages');
  const [
    visibleDropdown,
    setVisibleDropdown,
  ] = useState<VisibleDropdown | null>(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const mobile = useBreakpointDown('sm');

  const onToggleDropdownVisibility = React.useCallback(
    (name) => {
      if (!mobile) return;

      if (name === visibleDropdown) {
        setVisibleDropdown(null);
      } else {
        setVisibleDropdown(name);
      }
    },
    [visibleDropdown, mobile]
  );

  const { ref: navRef, height: navHeight } = useElementSize();
  const router = useRouter();

  useEffect(() => {
    cssVars.topBarHeight.setValue(`${Math.floor(navHeight)}px`);
    return () => cssVars.topBarHeight.setValue(0);
  }, [navHeight]);

  const { currentUserAccount, loading } = useCurrentUser();
  const { logout } = useAuthentication();
  const isLoggedIn = !loading && currentUserAccount;

  const showSignupButton = !isLoggedIn && !hideNavigationLinks;

  const userMenuLinks = (() => {
    let menuList = [];
    if (currentUserAccount?.roles?.includes('HEYKANTINE_CONSUMER')) {
      menuList.push({
        href: `${CUSTOMER_APP_HOST}/kantine/home`,
        label: t('common:navigation.heykantine'),
      });
      return menuList;
    }

    menuList = [
      {
        href: buildFunnelUrl({ router }),
        label: t('common:labels.requestCatering'),
      },
      {
        href: `${CUSTOMER_APP_HOST}/account/requests`,
        label: t('common:navigation.events'),
      },
      {
        href: `${CUSTOMER_APP_HOST}/account`,
        label: t('common:navigation.account'),
      },
    ];

    if (currentUserAccount?.owner?.heykantineEnabled) {
      menuList.push({
        href: `${CUSTOMER_APP_HOST}/account/kantine`,
        label: t('common:navigation.heykantine'),
      });
    }

    return menuList;
  })();

  const topLinks: MainLink[] = mainLinks || [
    {
      href: '',
      label: t('common:navigation.catering'),
      expandable: true,
      children: [
        {
          href: '/',
          label: t('common:navigation.individualOffer'),
        },
        {
          href: marketplaceURL('/catering'),
          label: t('common:navigation.bookAProvider'),
          outsideLink: true,
        },
        {
          href: '/branded-marketplace',
          label: t('common:navigation.brandedMarketplace'),
        },
      ],
    },
    {
      href: '/heykantine',
      label: t('common:navigation.onlineKantine'),
    },
    {
      href: '/events',
      label: t('common:navigation.eventManagement'),
    },
    {
      href: '',
      label: t('common:navigation.more'),
      expandable: true,
      children: [
        {
          href: '/caterer',
          label: t('common:navigation.forPartners'),
        },
        {
          href: '/blog',
          label: t('common:navigation.hub'),
        },
        {
          href: '/sustainability',
          label: t('common:navigation.sustainability'),
        },
        {
          href: '/about-us',
          label: t('common:navigation.about'),
        },
        {
          href: '/press',
          label: t('common:navigation.press'),
        },
      ],
    },
  ];

  return (
    <NavWrapper
      ref={navRef}
      className="main-navigation"
      style={{
        minHeight: 'inherit',
      }}
    >
      <NavbarEyebrow>
        <Container as="header">
          <NavbarEyebrowFlexWrapper>
            {mobile ? (
              <></>
            ) : (
              <TrustedShopsWrapper>
                <Stars>
                  <TrustedShopsStars />
                </Stars>
                <Rating>
                  <strong>{formatRating(getRating(ratings))}</strong>
                  <RatingText>&nbsp;{t('trustedShops.ratingText')}</RatingText>
                </Rating>
                <RatingCount>
                  {t('trustedShops.ratingCount', {
                    amount: formatRatingCount(getRatingCount(ratings)),
                  })}
                </RatingCount>
                <TSLogoBox display="flex" alignItems="center">
                  <TrustedShopsLogo />
                </TSLogoBox>
              </TrustedShopsWrapper>
            )}
            <PhoneLink href={CONTACT_PHONE.href}>
              <strong>{t('common:navigation.questions')}</strong>
              {CONTACT_PHONE.formatted}
            </PhoneLink>
          </NavbarEyebrowFlexWrapper>
        </Container>
      </NavbarEyebrow>
      <Navbar>
        <Container>
          <NavbarWrapper>
            <Link href="/" passHref>
              <NavbarLogo aria-label="Home" id="navbar-logo">
                <Logo />
              </NavbarLogo>
            </Link>
            <Menus>
              <MenuPrimary open={mobileMenuOpen}>
                <MenuList>
                  {topLinks.map((link) => (
                    <React.Fragment key={link.label}>
                      <MenuListItem data-dropdown={!!link.children}>
                        {/* If the item is expandable (has children), render as button */}
                        {link.children ? (
                          <MenuListItemLink
                            as="button"
                            aria-expanded={visibleDropdown === link.label}
                            onClick={() =>
                              onToggleDropdownVisibility(link.label)
                            }
                          >
                            {link.label}
                            {visibleDropdown === link.label ? (
                              <KeyboardArrowUpIcon fontSize="small" />
                            ) : (
                              <KeyboardArrowDownIcon fontSize="small" />
                            )}
                          </MenuListItemLink>
                        ) : // Otherwise render as a normal link
                        link?.outsideLink ? (
                          <UnstyledLink href={link.href}>
                            <MenuListItemLink>
                              {link.label}
                              {link?.new && (
                                <Box ml={1}>
                                  <NewPill />
                                </Box>
                              )}
                            </MenuListItemLink>
                          </UnstyledLink>
                        ) : (
                          <Link href={link.href} passHref>
                            <MenuListItemLink>
                              {link.label}
                              {link?.new && (
                                <Box ml={1}>
                                  <NewPill />
                                </Box>
                              )}
                            </MenuListItemLink>
                          </Link>
                        )}

                        {/* Render Dropdown Content for expandable items */}
                        {link.children && (
                          <DropdownContent
                            visible={visibleDropdown === link.label}
                            aria-expanded={visibleDropdown === link.label}
                            className="navigation-dropdown"
                          >
                            <DropdownMenu>
                              {link.children.map((child) => (
                                <Link
                                  key={child.href}
                                  href={child.href}
                                  passHref
                                >
                                  <DropdownMenuLink>
                                    {child.label}
                                    {child?.new && (
                                      <Box ml={1}>
                                        <NewPill />
                                      </Box>
                                    )}
                                  </DropdownMenuLink>
                                </Link>
                              ))}
                            </DropdownMenu>
                          </DropdownContent>
                        )}
                      </MenuListItem>
                    </React.Fragment>
                  ))}
                </MenuList>

                <MenuList>
                  {showSignupButton && !loading && (
                    <MenuListItemRight>
                      <MenuListItemLink as="button" id="open-login">
                        <a href={`${CUSTOMER_APP_HOST}/account/login`}>
                          {t('common:navigation.signIn')}
                        </a>
                      </MenuListItemLink>
                    </MenuListItemRight>
                  )}
                  {isLoggedIn && !loading && (
                    <MenuListItemRight data-dropdown="true">
                      <MenuListItemLink
                        as="button"
                        aria-expanded={visibleDropdown === 'account'}
                        onClick={() => onToggleDropdownVisibility('account')}
                      >
                        {currentUserAccount.firstName}{' '}
                        {currentUserAccount.lastName}
                        <Box display="flex" alignItems="center" ml={1}>
                          <AccountCircle />
                        </Box>
                      </MenuListItemLink>
                      <DropdownContent
                        visible={visibleDropdown === 'account'}
                        aria-expanded={visibleDropdown === 'account'}
                        className="navigation-dropdown"
                      >
                        <DropdownMenu>
                          {userMenuLinks.map(({ href, label }) => (
                            <Link key={href} href={href} passHref>
                              <DropdownMenuLink>{label}</DropdownMenuLink>
                            </Link>
                          ))}
                          <DropdownMenuLink onClick={logout} as="button">
                            <strong>{t('common:navigation.signOut')}</strong>
                          </DropdownMenuLink>
                        </DropdownMenu>
                      </DropdownContent>
                    </MenuListItemRight>
                  )}
                  <MenuListItemRight wrapsButton>
                    {typeof renderCTA === 'function' ? (
                      renderCTA()
                    ) : (
                      <Link href={buildFunnelUrl({ router })} passHref>
                        <Button
                          rounded
                          component="a"
                          rel="noopener"
                          id="navbar-find-caterers"
                        >
                          {t('common:labels.findCaterers')}
                        </Button>
                      </Link>
                    )}
                  </MenuListItemRight>
                </MenuList>
              </MenuPrimary>

              <Hidden mdUp>
                <HamburgerButton
                  aria-label={
                    mobileMenuOpen
                      ? 'Hide Navigation Menu'
                      : 'Show Navigation Menu'
                  }
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <MenuList>
                    <MenuListItemSecondary>
                      {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
                    </MenuListItemSecondary>
                  </MenuList>
                </HamburgerButton>
              </Hidden>
            </Menus>
          </NavbarWrapper>
        </Container>
      </Navbar>
    </NavWrapper>
  );
}

const UnstyledLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

const NavWrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  width: 100%;
`;

const NavbarEyebrow = styled.section`
  padding: 4px;
  background-color: ${(props) => props.theme.palette.grey[800]};
  color: white;
  font-size: 12px;
  z-index: ${(props) => props.theme.zIndex.appBar + 1};
`;

const PhoneLink = styled.a`
  color: inherit;
  text-decoration: inherit;
`;

const Navbar = styled.header`
  height: ${(props) => props.theme.navbar.height};
  background: white;
  z-index: ${(props) => props.theme.zIndex.appBar};
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[200]};
`;

const NavbarWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NavbarLogo = styled.a`
  width: 150px;
  height: ${(props) => props.theme.navbar.height};
  font-weight: 500;
  display: flex;
  align-items: center;

  & > span {
    display: inline-flex;
    margin-left: 10px;
    font-size: 18px;
  }
`;

const Menus = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const MenuPrimary = styled(Box)<{ open: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-left: 2em;

  ${(props) => props.theme.breakpoints.down('sm')} {
    margin-left: 0;
    position: fixed;
    top: var(${cssVars.topBarHeight.name});
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${(props) => props.theme.zIndex.appBar};
    background: white;
    overflow-y: auto;
    display: ${(props) => (props.open ? 'block' : 'none')};
  }
`;

const MenuList = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding-left: 0;

  ${(props) => props.theme.breakpoints.down('sm')} {
    display: block;
    flex-grow: unset;
    width: 100%;
  }
`;

const MenuListItem = styled.li`
  position: relative;
  min-height: ${(props) => props.theme.navbar.height};
  display: flex;
  align-items: center;

  ${(props) => props.theme.breakpoints.down('sm')} {
    display: block;
    min-height: auto;
  }

  ${(props) => props.theme.breakpoints.up('md')} {
    &[data-dropdown='true']:hover > .navigation-dropdown {
      display: block;
    }
  }
`;

const MenuListItemRight = styled(MenuListItem)<{ wrapsButton?: boolean }>`
  ${(props) => props.theme.breakpoints.down('sm')} {
    border-bottom: none;
    margin: ${(props) => (props.wrapsButton ? '8px 8px 0' : 'inherit')};

    > a {
      font-weight: ${(props) => (props.wrapsButton ? 600 : 400)};
      display: ${(props) => (props.wrapsButton ? 'flex' : 'inherit')};
      justify-content: ${(props) => (props.wrapsButton ? 'center' : 'initial')};
    }
  }
`;

const MenuListItemSecondary = styled(MenuListItem)`
  position: relative;
  min-height: ${(props) => props.theme.navbar.height};
  display: flex;
  align-items: center;
`;

const MenuListItemLink = styled.a`
  padding: 16px 16px;
  border: none;
  background: inherit;
  display: flex;
  width: 100%;
  margin-right: 0;
  flex-grow: 1;
  text-decoration: none;
  font-size: 14px;
  font-family: inherit;
  cursor: pointer;
  font-weight: 500;
  align-items: center;
  a {
    text-decoration: none;
    color: inherit;
  }

  &,
  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-weight: 700;
    justify-content: space-between;
  }
`;

const DropdownContent = styled.div<{ visible?: boolean }>`
  background: white;
  min-height: 30px;
  overflow: hidden;
  position: absolute;
  top: ${(props) => props.theme.navbar.height};
  z-index: ${(props) => props.theme.zIndex.appBar};
  padding: ${(props) => props.theme.spacing(1)}px;
  left: 14px;
  min-width: 250px;
  border-left: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-right: 1px solid ${(props) => props.theme.palette.grey[200]};
  border-bottom: 1px solid ${(props) => props.theme.palette.grey[200]};
  display: none;

  ${(props) => props.theme.breakpoints.down('sm')} {
    position: static;
    background: transparent;
    padding: 0 30px;
    left: 0;
    width: 100%;
    border: none;
    border-top: 1px solid ${(props) => props.theme.palette.grey[200]};
    border-bottom: 1px solid ${(props) => props.theme.palette.grey[200]};
    background: ${(props) => props.theme.palette.grey[50]};
    display: ${(props) => (props.visible ? 'block' : 'none')};
  }
`;

const DropdownMenu = styled.div`
  padding: ${(props) => props.theme.spacing(0.5)}px;
`;

const DropdownMenuLink = styled.a`
  display: block;
  padding: 10px 0;
  border: none;
  background: inherit;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.text.primary};
  text-decoration: none;
  font-size: 14px;

  &:hover {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 14px;
  }
`;

const HamburgerButton = styled(ButtonBase)`
  padding: 0px 20px;
  margin-left: auto;
  left: 16px;
`;

const NavbarEyebrowFlexWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: space-between;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    justify-content: center;
  }
`;

const TrustedShopsWrapper = styled.div`
  gap: 6px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
`;

const Stars = styled(Box)`
  height: 17px;
  width: 100%;
  min-width: 70px;
`;

const RatingText = styled.span`
  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: none;
  }
`;

const RatingCount = styled.span`
  flex-shrink: 0;
  ${({ theme }) => theme.breakpoints.down('md')} {
    display: none;
  }
`;

const Rating = styled.span`
  flex-shrink: 0;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: none;
  }
`;

const TSLogoBox = styled(Box)`
  min-height: 12px;
`;

export default Navigation;
